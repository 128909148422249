import { createSlice } from '@reduxjs/toolkit';
import api from '../../../config/api';

interface UserPointsState {
  staticPages: any;
}
const getInitialState = (): UserPointsState => ({
  staticPages: null,
});

const slice = createSlice({
  name: 'userPoints',
  initialState: getInitialState(),
  reducers: {
    addStaticPages: (state, action) => {
      state.staticPages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.fetchStaticFilePages.matchFulfilled, (state, { payload }) => {
      state.staticPages = payload;
    });
  },
});

export const { addStaticPages } = slice.actions;
export default slice.reducer;
