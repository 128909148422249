import moment from 'moment';

export const redirectToAdventCalendar = () => {
  const isAdventOngoing = moment().isBetween(
    moment('2024-12-01T00:00:00'),
    moment('2024-12-24T23:59:59'),
    null,
    '[]',
  );

  if (isAdventOngoing) {
    const adventCalendarRedirectLastDate = window.localStorage.getItem(
      'adventCalendarRedirectLastDate',
    );

    const mustRedirect =
      !adventCalendarRedirectLastDate ||
      !moment().startOf('day').isSame(moment(adventCalendarRedirectLastDate).startOf('day'));

    if (mustRedirect) {
      window.localStorage.setItem('adventCalendarRedirectLastDate', moment().format('YYYY-MM-DD'));

      setTimeout(() => {
        window.open('/advent-calendar', '_parent');
      }, 5000);
    }
  }
};
