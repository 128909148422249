import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, store } from '../store';
import { pushGAEvent } from '../../utils/gaUtils';
import { useGetUserPointsMutation } from '../../services/profile';

interface AppPushGAProps {
  route?: string;
}

type UserPoints = {
  total_points_consumed: number;
  total_codes_entered: number;
};

export const trackScreenView = async (
  currentRoute: string | undefined,
  t: (key: string) => string,
  { total_points_consumed, total_codes_entered }: UserPoints,
) => {
  const userData = store.getState().auth.userData;

  if (!userData) {
    pushGAEvent('datalayer_initialized', 'user', {
      loggedInStatus: t('GA-LOGGED_OUT_USER'),
      platform: Platform.OS,
      siteSection: currentRoute,
    });
    return;
  }

  const { consumerId, cluster, consents, referred, oldPlatformUser } = userData;

  const loggedInDetails = {
    loggedInStatus: t('GA-LOGGED_IN_USER'),
    id: consumerId,
    type: cluster?.type,
    customerLtvPoints: total_points_consumed,
    customerLtvCodes: total_codes_entered,
    mktAgreement: consents?.marketingConsent ? 'yes' : 'no',
    mktSurveys: consents?.researchConsent ? 'yes' : 'no',
    newsletterSubscriber: consents?.communicationConsent?.includes('Email') ? 'yes' : 'no',
    smsSubscriber: consents?.communicationConsent?.includes('SMS') ? 'yes' : 'no',
    phoneSubscriber: consents?.communicationConsent?.includes('Phone') ? 'yes' : 'no',
    referredMember: referred ? 'yes' : 'no',
    oldPlatformUser: oldPlatformUser ? 'yes' : 'no',
  };

  pushGAEvent('datalayer_initialized', 'user', {
    ...loggedInDetails,
    platform: Platform.OS,
    siteSection: currentRoute,
  });
};

export const AppPushGA = ({ route }: AppPushGAProps) => {
  const userData = useSelector((state: RootState) => state.auth.userData);
  const { t } = useTranslation();

  const [getUserPoints] = useGetUserPointsMutation();

  useEffect(() => {
    if (userData) {
      (async () => {
        try {
          const response = await getUserPoints({ history: 1 });
          if ('data' in response && response.data) {
            const { total_points_consumed } = response.data;
            const { activity_consumed, loyalty_consumed } = total_points_consumed;

            const total_codes_entered = Object.values(response.data.data)
              .flat()
              .filter((item) => item.subtype?.startsWith('pack_codes')).length;

            trackScreenView(route, t, {
              total_points_consumed: activity_consumed + loyalty_consumed,
              total_codes_entered,
            });
          } else if ('error' in response) {
            console.error('getUserPoints:', response.error);
          }
        } catch (error) {
          console.error('AppPushGA:', error);
        }
      })();
    }
  }, [userData?.consumerId]);

  return <React.Fragment></React.Fragment>;
};
