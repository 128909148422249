import { useEffect, useRef, useState } from 'react';
import { Image, Linking, Pressable, ScrollView, useWindowDimensions, View } from 'react-native';

import { pushGAEvent } from '../../../utils/gaUtils';
import { NotificationData } from '../../../models/Feed.model';
import { useIsMobile } from '../../hooks/useIsMobile';
import { sharedStyles } from '../../../config/theme/shared.styles';
import BATText from '../common/BATText';
import BATButtonNew from '../common/BATButtonNew';
import BATDialog from '../common/BATDialog';
import BATHTMLRenderer from '../BATHTMLRenderer';
import colors from '../../../config/theme/shared/colors';

enum POPUPTYPE {
  POPUPWITHBACKGROUNDIMAGE = 'Image and Text Overlayed',
  POPUPWITHIMAGE = 'Image and Text Separated',
  POPUPWITHVIDEO = 'Video and Text Separated',
}

interface BATAnnouncementPopupProps {
  notifications: NotificationData[];
  handleSeenNotification: (id: string) => void;
}

const BATAnnouncementPopup = ({
  notifications,
  handleSeenNotification,
}: BATAnnouncementPopupProps) => {
  const [queue, setQueue] = useState<NotificationData[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const { isMobile } = useIsMobile();
  const { height, width } = useWindowDimensions();
  const isSquare = queue[0]?.template?.isSquare;

  useEffect(() => {
    setQueue(notifications);
  }, [notifications]);

  const handleEnded = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handleOnClose = (action: 'button_click' | 'close') => {
    handleSeenNotification(queue[0].nid);
    const newQueue = [...queue];
    newQueue.shift();
    setQueue(newQueue);

    pushGAEvent('pop_up_interaction', 'details', {
      action,
    });
  };

  const handleOnPress = () => {
    if (queue[0].template.cta_link_external) Linking.openURL(queue[0].template.cta_link_external);
    handleOnClose('button_click');
  };

  const formatText = (text: string) => {
    return text.replaceAll(/<[^>]*>/g, '');
  };

  if (!queue.length) {
    return null;
  }

  const contentTexts = (
    <>
      {queue[0].template.title && (
        <BATText
          type="heading-4"
          color={colors.grey_title}
          customStyle={{
            marginTop: 8,
            fontSize:
              width > 800 && height < 500
                ? 16
                : width > 800 && height >= 500 && height <= 700
                ? 22
                : isMobile
                ? 18
                : 24,
            lineHeight:
              width > 800 && height < 500
                ? 20
                : width > 800 && height >= 500 && height <= 700
                ? 30
                : isMobile
                ? 24
                : 32,
          }}>
          {queue[0].template.title}
        </BATText>
      )}
      {queue[0].template.body && (
        <BATHTMLRenderer
          html={queue[0].template.body}
          parentStyle={{
            body: {
              color: colors.grey_text,
              fontSize:
                width > 800 && height < 500
                  ? 14
                  : width > 800 && height >= 500 && height <= 700
                  ? 16
                  : isMobile
                  ? 16
                  : 18,
              lineHeight:
                width > 800 && height < 500
                  ? 18
                  : width > 800 && height >= 500 && height <= 700
                  ? 24
                  : isMobile
                  ? 24
                  : 28,
              fontFamily: 'Roboto_400Regular',
              margin: 0,
            },
            p: {
              margin: 0,
            },
            h2: {
              margin: 0,
            },
            h6: {
              margin: 0,
            },
          }}
        />
      )}
    </>
  );

  const content = (
    <Pressable
      style={[
        sharedStyles.flex_1,
        queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHBACKGROUNDIMAGE
          ? sharedStyles.cursorPointer
          : sharedStyles.cursorDefault,
        queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHBACKGROUNDIMAGE && {
          aspectRatio: isSquare ? 1 / 1 : 16 / 9,
        },
      ]}
      onPress={
        queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHBACKGROUNDIMAGE ? handleOnPress : null
      }>
      {queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHVIDEO && (
        <View
          style={[
            sharedStyles.relative,
            sharedStyles.marginVertical,
            { aspectRatio: isSquare ? 1 / 1 : 16 / 9 },
          ]}>
          <video
            ref={videoRef}
            autoPlay
            muted
            playsInline
            height="100%"
            width="100%"
            style={{ objectFit: 'cover', borderRadius: 16 }}
            onEnded={handleEnded}
            onPlay={handlePlay}>
            <source src={`${process.env.API_URL}${queue[0].template.video}`} type="video/mp4" />
          </video>
        </View>
      )}
      {queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHIMAGE && queue[0].template.image && (
        <Image
          style={[
            {
              width: '100%',
              aspectRatio: isSquare ? 1 / 1 : 16 / 9,
              borderRadius: 16,
              marginBottom: 8,
            },
          ]}
          source={{ uri: queue[0].template.image }}
        />
      )}
      {queue[0].template.pop_up_type !== POPUPTYPE.POPUPWITHBACKGROUNDIMAGE && (
        <>
          {width > 800 && height < 350 ? (
            contentTexts
          ) : (
            <ScrollView style={{ flex: 1 }}>{contentTexts}</ScrollView>
          )}
        </>
      )}
    </Pressable>
  );

  return (
    <BATDialog
      visible={!!queue.length}
      onDismiss={() => handleOnClose('close')}
      dismissable={!isPlaying}
      customTitleStyles={{ marginTop: width > 800 && height < 700 ? 0 : 22 }}
      customStyles={[
        sharedStyles.widthFull,
        queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHBACKGROUNDIMAGE && {
          backgroundImage: `url("${queue[0].template.image}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
        {
          maxHeight: isMobile ? 0.85 * height : 0.95 * height,
          maxWidth: isMobile
            ? '90%'
            : width > 800 && height < 500
            ? '25%'
            : width > 800 && height >= 500 && height < 700
            ? '40%'
            : 480,
        },
      ]}
      closeIconStyles={{ opacity: isPlaying ? 0.3 : 1 }}
      body={
        width > 800 && height <= 350 ? (
          <ScrollView style={{ flex: 1, paddingRight: 8 }}>{content}</ScrollView>
        ) : (
          <>{content}</>
        )
      }
      bodyStyles={{
        paddingBottom: 8,
        paddingRight: width > 800 && height <= 500 ? 16 : 24,
        flex: 1,
      }}
      footer={
        queue[0].template.pop_up_type !== POPUPTYPE.POPUPWITHBACKGROUNDIMAGE &&
        queue[0].template.cta_text &&
        queue[0].template.cta_link_external ? (
          <View
            style={[
              sharedStyles.widthFull,
              sharedStyles.displayFlexWrap,
              queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHBACKGROUNDIMAGE
                ? sharedStyles.justifyContentCenter
                : sharedStyles.justifyContentStart,
              sharedStyles.paddingBottom,
              { paddingHorizontal: 16 },
            ]}>
            <BATButtonNew
              variant="contained"
              handleSubmitButton={handleOnPress}
              size={width > 800 && height < 500 ? 'small' : 'base'}>
              {queue[0].template.cta_text}
            </BATButtonNew>
          </View>
        ) : undefined
      }
    />
  );
};

export default BATAnnouncementPopup;
