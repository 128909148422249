import IframeRenderer from '@native-html/iframe-plugin';
import React, { useEffect } from 'react';
import { CustomBlockRenderer } from 'react-native-render-html';
import { useImmer } from 'use-immer';

export const BATIframeRenderer: CustomBlockRenderer = (props) => {
  const [state, setState] = useImmer({ props });

  useEffect(() => {
    setState((draft) => {
      // @ts-expect-error
      draft.props = props;

      let width = parseInt(props?.tnode?.attributes?.width);
      let height = parseInt(props?.tnode?.attributes?.height);
      width = !isNaN(width) ? width : 360;
      height = !isNaN(height) ? height : 640;

      if (draft.props.style == null) {
        draft.props.style = {};
      }

      draft.props.style.aspectRatio = width / height;

      delete draft.props?.tnode?.attributes?.width;
      delete draft.props?.tnode?.attributes?.height;
    });
  }, [props, setState]);

  return <IframeRenderer {...state.props} />;
};
