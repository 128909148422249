import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import {
  AdventCalendarPrizeStates,
  AdventCalendarState,
  GetAdventCalendarPrizesResponse,
  GetAdventCalendarResponse,
  GetAdventCalendarsResponse,
} from '../models';

const getInitialState = (): AdventCalendarState => ({ adventCalendars: [] });

const slice = createSlice({
  name: 'adventCalendars',
  initialState: getInitialState(),
  reducers: {
    setAdventCalendars: (state, action: { payload: GetAdventCalendarsResponse }) => {
      state.adventCalendars = action.payload.results.map(
        ({ nid, field_brand, field_image, title }) => ({
          id: nid,
          brand: field_brand,
          imageUrl: field_image,
          title: title,
          description: '',
          backgroundUrl: '',
          isSpecial: false,
          titleColor: '',
          subtitleColor: '',
          prizes: [],
        }),
      );
    },
    setAdventCalendar: (
      state,
      action: {
        payload: {
          adventCalendarId: string;
          adventCalendarResponse: GetAdventCalendarResponse;
          adventCalendarPrizesResponse: GetAdventCalendarPrizesResponse;
        };
      },
    ) => {
      const { adventCalendarId, adventCalendarResponse, adventCalendarPrizesResponse } =
        action.payload;

      const targetedAdventCalendar = state.adventCalendars.find(
        ({ id }) => id === adventCalendarId,
      );

      if (!targetedAdventCalendar) {
        return;
      }

      targetedAdventCalendar.description = adventCalendarResponse[0].body;
      targetedAdventCalendar.backgroundUrl = adventCalendarResponse[0].background_image;
      targetedAdventCalendar.isSpecial = !!adventCalendarResponse[0].field_is_special;
      targetedAdventCalendar.titleColor = adventCalendarResponse[0].field_title_color;
      targetedAdventCalendar.subtitleColor = adventCalendarResponse[0].field_subtitle_color;
      targetedAdventCalendar.prizes = adventCalendarResponse[0].prizes.map(
        ({ field_prize, field_image }, prizeIndex) => {
          const prizeDetails = adventCalendarPrizesResponse.find(({ nid }) => +nid === field_prize);

          let type, name, slug, imageUrl, subtitle, state;
          if (!prizeDetails) {
            type = '';
            name = '';
            slug = '';
            imageUrl = '';
            subtitle = 'În curând';
            state = AdventCalendarPrizeStates.FUTURE;
          } else {
            type = prizeDetails.type;
            name = prizeDetails.title;
            slug = prizeDetails.url_alias.replace(/^\//, '');
            imageUrl = prizeDetails.field_image;
            const currentMoment = moment().startOf('day');
            const prizeMoment = moment(
              prizeDetails.field_hidden_offer_period.replace(/^\s+|\s+$/g, ''),
            ).startOf('day');
            if (prizeMoment.isBefore(currentMoment)) {
              subtitle = prizeDetails.title;
              state = AdventCalendarPrizeStates.PAST;
            } else {
              if (prizeDetails.has_access_to_hidden_offer) {
                subtitle = prizeDetails.title;
                state = AdventCalendarPrizeStates.PRESENT_UNLOCKED;
              } else {
                subtitle = 'Vezi premiul';
                state = AdventCalendarPrizeStates.PRESENT_LOCKED;
              }
            }
          }

          return {
            id: `${field_prize}`,
            type,
            name,
            slug,
            backgroundUrl: adventCalendarResponse[0].prize_background_image,
            imageUrl,
            coverUrl: field_image || adventCalendarResponse[0].default_prize_image,
            coverLockUrl: adventCalendarResponse[0].field_general_prize_icon,
            title: `Ziua ${prizeIndex + 1}`,
            subtitle,
            state,
          };
        },
      );
    },
    updatePrizeUnlock: (
      state,
      action: {
        payload: {
          adventCalendarId: string;
          prizeId: string;
        };
      },
    ) => {
      const { adventCalendarId, prizeId } = action.payload;

      const targetedAdventCalendar = state.adventCalendars.find(
        ({ id }) => id === adventCalendarId,
      );

      if (!targetedAdventCalendar) {
        return;
      }

      const targetedPrize = targetedAdventCalendar.prizes.find(({ id }) => id === prizeId);

      if (!targetedPrize) {
        return;
      }

      targetedPrize.subtitle = targetedPrize.name;
      targetedPrize.state = AdventCalendarPrizeStates.PRESENT_UNLOCKED;
    },
  },
});

export const { setAdventCalendars, setAdventCalendar, updatePrizeUnlock } = slice.actions;

export default slice.reducer;
