export interface GetAdventCalendarsResponse {
  results: {
    nid: string;
    title: string;
    field_brand: string;
    field_image: string;
    field_advent_period: string;
  }[];
}

export type GetAdventCalendarResponse = {
  nid: number;
  title: string;
  body: string;
  field_brand: string;
  field_general_prize_icon: string;
  field_nr_of_prizes: number;
  field_image: string;
  background_image: string;
  prize_background_image: string;
  default_prize_image: string;
  field_advent_period: string;
  prizes: [
    {
      field_prize: number;
      field_image: string;
    },
    {
      field_prize: number;
      field_image: string;
    },
    {
      field_prize: number;
      field_image: string;
    },
  ];
  field_is_special: 0 | 1 | '';
  field_subtitle_color: string;
  field_title_color: string;
}[];

export type GetAdventCalendarPrizesResponse = {
  nid: string;
  type: string;
  title: string;
  created: string;
  field_brand: string;
  field_image: string;
  url_alias: string;
  brand_url_alias: string;
  territory_url_alias: string;
  field_hidden_offer_period: string;
  has_access_to_hidden_offer: boolean;
}[];

export enum AdventCalendarPrizeStates {
  PAST,
  PRESENT_LOCKED,
  PRESENT_UNLOCKED,
  FUTURE,
}

export interface AdventCalendarPrize {
  id: string;
  type: string;
  name: string;
  slug: string;
  backgroundUrl: string;
  imageUrl: string;
  coverUrl: string;
  coverLockUrl: string;
  title: string;
  subtitle: string;
  state: AdventCalendarPrizeStates;
}

interface AdventCalendar {
  id: string;
  brand: string;
  imageUrl: string;
  title: string;
  description: string;
  backgroundUrl: string;
  isSpecial: boolean;
  titleColor: string;
  subtitleColor: string;
  prizes: AdventCalendarPrize[];
}

export interface AdventCalendarState {
  adventCalendars: AdventCalendar[];
}
