import { iframeModel } from '@native-html/iframe-plugin';
import React, { useEffect, useMemo, useState } from 'react';
import { Pressable, StyleSheet, useWindowDimensions } from 'react-native';
import RenderHtml, { MixedStyleDeclaration, RenderHTMLProps } from 'react-native-render-html';
import WebView from 'react-native-webview';
import Constants from '../../config/theme/shared/constants';
import colors from '../../config/theme/shared/colors';
import { BATIframeRenderer } from './BATIframeRenderer';
import BATText from './common/BATText';

interface BATHTMLRendererProps {
  html: string;
  onPressLinkHandlers?: Record<string, () => void>;
  parentStyle?: {};
}

const BATHTMLRenderer = ({ html, onPressLinkHandlers, parentStyle }: BATHTMLRendererProps) => {
  // States
  const [iframeWidth, setIframeWidth] = useState(0);

  // Other
  const { width } = useWindowDimensions();

  const renderers: RenderHTMLProps['renderers'] = useMemo(() => {
    const renderers = {
      iframe: BATIframeRenderer,
    };

    if (onPressLinkHandlers) {
      renderers.a = (props: any) => {
        return (
          <Pressable
            onPress={() => {
              onPressLinkHandlers[props.renderIndex] && onPressLinkHandlers[props.renderIndex]();
            }}>
            <BATText type="body-small" color={colors.violet}>
              {props.tnode.children[0]?.data}
            </BATText>
          </Pressable>
        );
      };
    }

    return renderers;
  }, []);

  const customHTMLElementModels: RenderHTMLProps['customHTMLElementModels'] = useMemo(
    () => ({
      iframe: iframeModel,
    }),
    [],
  );

  const renderersProps: RenderHTMLProps['renderersProps'] = useMemo(
    () => ({
      iframe: {
        width: '100%',
        height: '100%',
        webViewProps: {
          style: {
            width: '100%',
            height: '100%',
          },
        },
      },
    }),
    [],
  );

  const systemFonts = [
    'Rubik_300Light',
    'Rubik_400Regular',
    'Rubik_500Medium',
    'Rubik_600SemiBold',
    'Rubik_700Bold',
    'Roboto_400Regular',
    'IcoMoon',
  ];

  useEffect(() => {
    setIframeWidth(
      width <= Constants.screenContainerMaxWidth ? width - 24 : Constants.screenContainerMaxWidth,
    );
  }, [width]);

  return (
    <RenderHtml
      tagsStyles={{
        ...tagsStyles,
        ...parentStyle,
        iframe: {
          width: iframeWidth,
        },
      }}
      renderers={renderers}
      WebView={WebView}
      source={{
        html: html,
      }}
      customHTMLElementModels={customHTMLElementModels}
      renderersProps={renderersProps}
      systemFonts={systemFonts}
    />
  );
};

export default BATHTMLRenderer;

const styles = StyleSheet.create({});

const tagsStyles: Readonly<Record<string, MixedStyleDeclaration>> = {
  body: {
    color: '#fff',
    fontFamily: 'Rubik_300Light',
  },
  h2: {
    fontSize: 18,
    lineHeight: 21,
    marginBottom: 17,
    marginTop: 0,
    letterSpacing: -0.32,
  },
  h6: {
    fontSize: 18,
    lineHeight: 21,
    marginBottom: 17,
    marginTop: 0,
    letterSpacing: -0.32,
  },
  p: {
    marginTop: 0,
    marginBottom: 25,
  },
  img: {
    borderRadius: 16,
    objectFit: 'cover',
  },
  iframe: {
    width: '100%',
    height: '100%',
  },
};
